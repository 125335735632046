<template>
  <createCard />
</template>

<script>
import createCard from '@/components/create/createCard.vue'

export default {
  name: 'CreateView',
  components: {
    createCard
  }
}
</script>
