<template>
  <manageCards />
</template>

<script>
import manageCards from '@/components/manage/mainPart.vue'

export default {
  name: 'ManageView',
  components: {
    manageCards
  }
}
</script>
