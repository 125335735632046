<template>
  <main-Part />
</template>

<script>
import mainPart from '@/components/redeem/main-Part.vue'

export default {
  components: {
    mainPart
  }
}
</script>
